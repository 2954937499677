import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
import { AUTHORSLIST } from "../constants";

function AuthorInfoScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  let { slug } = useParams();
  const [author, setAuthor] = useState(null);

  useEffect(() => {
    setAuthor(findItemBySlug(slug));
  }, []);

  const findItemBySlug = (slug) => {
    return AUTHORSLIST.find((item) => item.slug === slug);
  };

  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col ">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            {author ? (
              <div className="flex md:flex-row flex-col">
                <div className="md:w-1/3 w-full px-3 flex md:flex-col flex-wrap">
                  {author &&
                    author?.images?.map((image, index) => (
                      <img className="m-2" src={image} alt={index} />
                    ))}
                </div>
                <div className="md:w-2/3 w-full px-3">
                  <div className="font-bold text-center text-xl mt-5 mb-5">
                    {author.name}
                  </div>
                  <div>
                    {author.bio?.map((item, index) => (
                      <div className="my-2">{item}</div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default AuthorInfoScreen;
