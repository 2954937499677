import React from "react";
import backgroundImage from "../assets/images/background-image.jpg";
import PartnerWidget from "../widgets/PartnerWidget";
import FooterWidget from "../widgets/FooterWidget";
import HeaderWidget from "../widgets/HeaderWidget";
import { AUTHORSLIST } from "../constants";

function AuthorsScreen() {
  return (
    <div
      className="w-screen min-h-screen bg-center bg-cover bg-fixed bg-no-repeat overflow-y-scroll"
      // style={{ backgroundImage: "url(" + backgroundImage + ")" }}
    >
      <div className="overflow-auto h-screen flex flex-col ">
        <img
          src={backgroundImage}
          className="w-screen h-screen fixed top-0 bottom-0 opacity-70 z-0 object-cover"
        />
        <HeaderWidget />
        {/*  */}
        <div className="px-3 md:mt-0 mt-28 flex-1 z-20">
          <div className="container md:mx-auto  px-5 py-10 mt-5 md:w-2/3 w-full  mb-5 min-h-[70vh] bg-white">
            <div className="grid md:grid-cols-4 grid-cols-2  md:gap-4 gap-2">
              {AUTHORSLIST?.map((author, index) => (
                <a href={"/authors/" + author.slug} key={index} className="">
                  <img
                    src={author.image}
                    alt={author.name}
                    className="w-full h-96 object-center"
                  />
                  <div className="font-bold text-center my-2 ">
                    {author.name}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        {/*  */}
        <PartnerWidget />
        {/* footer */}
        <FooterWidget />
        {/*  */}
      </div>
    </div>
  );
}

export default AuthorsScreen;
